import React from "react";
import ChallengeByStatus from "../../components/common/ChallengeByStatus";

function NewChallenge({ update_user }) {
  return (
    <div>
      <p className="pageHeading">New Challenge</p>

      <ChallengeByStatus status="NEW" update_user={update_user} />
    </div>
  );
}

export default NewChallenge;

import React from "react";
import ReportByStatus from "../../components/common/ReportByStatus";

function PenaltyReport({ view_user, view_phone }) {
  return (
    <div>
      <p className="pageHeading mb-20">Penalty Report</p>

      <ReportByStatus
        status="PENALTY"
        view_user={view_user}
        view_phone={view_phone}
      />
    </div>
  );
}

export default PenaltyReport;

import React, { useEffect, useState } from "react";
import API_MANAGER from "../API";
import {
  Row,
  Table,
  message,
  Col,
  Modal,
  Form,
  Select,
  Input,
  Button,
  Checkbox,
} from "antd";
import moment from "moment";
import CustomPaginationWithPageSize from "../components/common/CustomPaginationWithPageSize";
import HELPERS from "../utils/helper";

function AdminPanelty({ permissions }) {
  const [adminList, setAdminList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checkManualAmount, setCheckManualAmount] = useState(false);
  const [page, setPage] = useState({
    page: 1,
    limit: 10,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState();

  const [form] = Form.useForm();
  const getData = async () => {
    try {
      let params = {
        ...page,
      };

      setLoading(true);
      const response = await API_MANAGER.getAdminPanelty(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const getAdminList = async () => {
    try {
      const response = await API_MANAGER.getAllAdmin();
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setAdminList(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const createPanelty = async (data) => {
    try {
      setLoading(true);
      await API_MANAGER.createAdminPanelty(data);
      setLoading(false);
      setOpenModal(false);
      form.resetFields();
      getData();
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const editpanelty = async (data) => {
    try {
      setLoading(true);
      await API_MANAGER.editAdminPanelty(edit?._id, data);
      setLoading(false);
      setOpenModal(false);
      form.resetFields();
      setEdit(null);
      getData();
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const deletePanelty = async (id) => {
    try {
      setLoading(true);
      await API_MANAGER.deleteAdminPanelty(id);
      setLoading(false);
      getData();
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    getAdminList();
  }, []);
  let columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },

    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (item) => <span>{item?.Name}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (item, row) => (
        <span>
          {item}{" "}
          {row?.gameId && (
            <>
              <Button className="secondary_button">
                <a
                  href={`/view-challenges/${row?.gameId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Game
                </a>
              </Button>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Panelty By",
      dataIndex: "action_by",
      key: "action_by",
      render: (item) => <span>{item?.Name}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
  ];
  if (permissions?.update_user) {
    columns.push({
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (item, row) => (
        <Row gutter={[8, 8]}>
          <Col>
            <Button
              onClick={() => {
                setEdit(row);
                form.setFieldsValue({
                  admin: row?.admin,
                  reason: row?.reason,
                  amount: row?.amount,
                });
                setOpenModal(true);
              }}
            >
              Edit
            </Button>
          </Col>
          <Col>
            <Button onClick={() => deletePanelty(row?._id)}>Delete</Button>
          </Col>
        </Row>
      ),
    });
  }
  return (
    <div>
      <p className="pageHeading">Admin Panelty</p>
      <Row justify={"end"}>
        <Col>
          <Button onClick={() => setOpenModal(true)}>Add Panelty</Button>
        </Col>
      </Row>
      <Modal
        open={openModal}
        footer={false}
        centered
        onCancel={() => {
          setOpenModal(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(data) => (edit ? editpanelty(data) : createPanelty(data))}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name={"admin"}
                label="Admin"
                rules={[
                  {
                    required: true,
                    message: "Please select admin",
                  },
                ]}
              >
                <Select placeholder="Select User" showSearch>
                  {adminList?.map((user) => (
                    <Select.Option value={user?._id} key={user?.id}>
                      {`${user?.Name ? user?.Name : " "} - ${user?.Phone}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"reason"}
                label="Remark"
                rules={[
                  {
                    required: true,
                    message: "Please enter remark.",
                  },
                ]}
              >
                <Input placeholder="Enter reason" />
              </Form.Item>
            </Col>
            {checkManualAmount ? (
              <Col span={24}>
                <Form.Item
                  name={"amount"}
                  label="Panelty Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select panelty type.",
                    },
                  ]}
                >
                  <Input placeholder="Enter panlety amount" type="number" />
                </Form.Item>
              </Col>
            ) : (
              <Col span={24}>
                <Form.Item
                  name={"amount"}
                  label="Panelty Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select panelty type.",
                    },
                  ]}
                >
                  <Select placeholder="Select panelty type">
                    <Select.Option value={20}>
                      {`Support (Amount Rs 20)`}
                    </Select.Option>
                    <Select.Option value={100}>
                      {`Game (Amount Rs 100)`}
                    </Select.Option>
                    <Select.Option value={100}>
                      {`Other (Amount Rs 100)`}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {permissions?.all_admin && (
              <Col span={24} className="mb-20">
                <Checkbox
                  checked={checkManualAmount}
                  onChange={(e) => setCheckManualAmount(e?.target?.checked)}
                >
                  Custom Panelty
                </Checkbox>
              </Col>
            )}
          </Row>
          <Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>

      <div>
        <Table
          columns={columns}
          dataSource={data?.result ? data?.result : []}
          pagination={false}
          className="table"
          loading={loading}
          rowKey={"id"}
          style={{ marginTop: "24px" }}
          scroll={{
            // y: "calc(100vh - 400px)",
            x: "calc(768px + 40%)",
          }}
        />
        <CustomPaginationWithPageSize
          currentPage={page}
          setCurrentPage={setPage}
          total={data?.totalCount}
        />
      </div>
    </div>
  );
}

export default AdminPanelty;

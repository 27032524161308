import React, { useEffect, useState } from "react";
import { Row, Col, Table, Dropdown, message, Button } from "antd";
import infoIcon from "../../Assets/infoIcon.svg";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import HELPERS from "../../utils/helper";

function AllHoldUsers({ view_user, view_phone }) {
  const [page, setPage] = useState({
    page: 1,
    limit: 20,
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getData = async () => {
    try {
      setLoading(true);
      const response = await API_MANAGER.getHoldUsers({});
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong!");
    }
  };

  const deletePlayer = async (player) => {
    const confirmBox = window.confirm(`are you sure delete ${player?.Name}`);
    if (confirmBox === true) {
      try {
        await API_MANAGER.deletePlayer(player?._id);
        message.success("User deleted successfully!");
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },

    {
      title: "Hold",
      dataIndex: "hold_balance",
      key: "hold_balance",
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Button>
            <a
              className="tableLink"
              href={`/user/view/${row?._id}`}
              target="_blank"
            >
              View User
            </a>
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [page]);
  return (
    <div>
      <p className="pageHeading">All Hold Users</p>

      <Table
        columns={columns}
        dataSource={data?.result ? data?.result : []}
        pagination={false}
        loading={loading}
        className="table"
        rowKey={"id"}
        style={{ marginTop: "24px", borderRadius: "0px" }}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(767px)",
        }}
      />
      {/* <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      /> */}
    </div>
  );
}

export default AllHoldUsers;

import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Dropdown,
  message,
  Form,
  Select,
  Input,
  Button,
  Modal,
} from "antd";
import infoIcon from "../../Assets/infoIcon.svg";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";
import HELPERS from "../../utils/helper";
import debounce from "lodash.debounce";

function AllUsers({ view_user, view_phone }) {
  const [page, setPage] = useState({
    page: 1,
    limit: 20,
  });
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [blockUser, setBlockUser] = useState(null);
  const [redAlertUser, setRedAlertUser] = useState(null);
  const navigate = useNavigate();
  const getData = async () => {
    try {
      let params = {
        ...page,
      };
      if (search && searchType) {
        params["search"] = search;
        params["searchType"] = searchType;
      }
      if (searchByStatus) {
        params["searchStatus"] = searchByStatus;
      }
      setLoading(true);
      const response = await API_MANAGER.getAllUsers(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong!");
    }
  };
  const blockUnblockPlayer = async (player, data) => {
    const confirmBox = window.confirm(
      `are you sure that you want to block ${player?.Name}`
    );
    if (confirmBox === true) {
      const userType = player?.user_type === "Block" ? "User" : "Block";

      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: player?._id,
          user_type: userType,
          remark: data?.remark,
        });
        setBlockUser(null);
        message.success("Updated successfully!");
        getData();
      } catch (error) {
        setBlockUser(null);

        message.error("Something went wrong!");
      }
    }
  };
  const handleRedHighlightUser = async (player, data) => {
    const confirmBox = window.confirm(
      `are you sure that you want to ${
        player?.isRedHighlight ? "remove" : "set"
      } red alert ${player?.Name}`
    );
    if (confirmBox === true) {
      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: player?._id,
          isRedHighlight: player?.isRedHighlight ? false : true,
          remark: data?.remark,
          isUpdateRedAlert: true,
        });
        setRedAlertUser(null);
        message.success("Updated successfully!");
        getData();
      } catch (error) {
        setRedAlertUser(null);

        message.error("Something went wrong!");
      }
    }
  };
  const deletePlayer = async (player) => {
    const confirmBox = window.confirm(`are you sure delete ${player?.Name}`);
    if (confirmBox === true) {
      try {
        await API_MANAGER.deletePlayer(player?._id);
        message.success("User deleted successfully!");
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const handleItems = (row) => {
    return [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            <Row>
              <Col
                className="item"
                span={24}
                onClick={() => {
                  if (view_user) navigate(`/user/view/${row?._id}`);
                }}
              >
                <span>View</span>
              </Col>
            </Row>

            <Row>
              <Col
                onClick={() => {
                  setBlockUser(row);
                }}
                className="item"
                span={24}
              >
                <span>{row?.user_type === "Block" ? "Unblock" : "Block"}</span>
              </Col>
            </Row>
            <Row>
              <Col
                onClick={() => {
                  setRedAlertUser(row);
                }}
                className="item"
                span={24}
              >
                <span>
                  {row?.isRedHighlight ? "Remove Red Alert" : "Set Red Alert"}
                </span>
              </Col>
            </Row>

            {/* <Row>
              <Col
                className="item"
                span={24}
                // onClick={() => withdrawPass(row?.txn_id)}
              >
                <span>Edit</span>
              </Col>
            </Row> */}

            <Row>
              <Col className="item" span={24} onClick={() => deletePlayer(row)}>
                <span>Delete</span>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
  };
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (item, row) => (
        <>
          {view_user ? (
            <a className="tableLink" href={`/user/view/${row?._id}`}>
              {item}
            </a>
          ) : (
            <>{item}</>
          )}
        </>
      ),
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      render: (item) => <span>{view_phone ? item : "N/A"}</span>,
    },
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
    },
    {
      title: "Balance",
      dataIndex: "Wallet_balance",
      key: "Wallet_balance",
      render: (item, row) => <span>₹{row?.Wallet_balance}</span>,
    },

    {
      title: "Game hold",
      dataIndex: "hold_balance",
      key: "hold_balance",
    },
    {
      title: "Reffered By",
      dataIndex: "referral",
      key: "referral",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
      key: "action_by",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            {row?.action_by?.Phone}{" "}
            {row?.action_by?.Name ? `(${row?.action_by?.Name})` : ""}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Dropdown
            placement="bottom"
            overlayClassName="action-dropdown"
            menu={{
              items: handleItems(row),
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                // setRowData(row);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];
  const fetchSearchResults = useCallback(
    debounce(async (search) => {
      if (searchType === "Phone" && search?.length < 10) {
        return;
      }
      if (!search) {
        return;
      }
      getData();
    }, 500),
    [search, searchType]
  );
  useEffect(() => {
    fetchSearchResults(search);
    return fetchSearchResults.cancel; // Cancel debounce on unmount
  }, [search, searchType, page, fetchSearchResults]);
  return (
    <div>
      <p className="pageHeading">All Users</p>

      <Form layout="vertical">
        <Row gutter={24} align={"middle"}>
          <Col xs={12} lg={6}>
            <Form.Item label="Search By" name={"searchType"}>
              <Select
                className="selectBox"
                onChange={(e) => setSearchType(e)}
                placeholder="Search By"
              >
                <Select.Option value="Name">Name</Select.Option>
                <Select.Option value="Phone">Phone</Select.Option>
                <Select.Option value="_id">User Id</Select.Option>
                <Select.Option value="referral">Referral By</Select.Option>
                <Select.Option value="referral_code">
                  Referral Code
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item label="Search " name={"search"}>
              <Input
                placeholder="Search"
                className="inputBox"
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item label="Select Status" name={"status"}>
              <Select
                className="selectBox"
                placeholder="Select Status"
                onChange={(e) => setSearchByStatus(e)}
              >
                <Select.Option value="verified">Verified</Select.Option>
                <Select.Option value="unverified">Unverified</Select.Option>
                <Select.Option value="BLOCK">Blocked</Select.Option>
                <Select.Option value="HOLD_BALANCE">Hold</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="mt-32">
            <Form.Item>
              <Button
                className=""
                onClick={() => {
                  if (page?.page !== 1)
                    setPage({
                      ...page,
                      page: 1,
                    });
                  else getData();
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={data?.result ? data?.result : []}
        pagination={false}
        loading={loading}
        className="table"
        rowKey={"id"}
        style={{ marginTop: "24px", borderRadius: "0px" }}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(767px)",
        }}
      />
      <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      />

      <Modal
        centered
        open={blockUser}
        closable={true}
        footer={false}
        onCancel={() => setBlockUser(null)}
        // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => blockUnblockPlayer(blockUser, data)}
        >
          <Form.Item
            name={"remark"}
            label="Remark*"
            rules={[{ required: true, message: "Please enter remark." }]}
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={redAlertUser}
        closable={true}
        footer={false}
        onCancel={() => setRedAlertUser(null)}
        // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => handleRedHighlightUser(redAlertUser, data)}
        >
          <Form.Item
            name={"remark"}
            label="Remark*"
            rules={[{ required: true, message: "Please enter remark." }]}
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default AllUsers;

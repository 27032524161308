import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import { Row, message, Button, Col, Input, Modal, Form } from "antd";
import moment from "moment";
import axios from "axios";
import HELPERS from "../../utils/helper";
function ViewStarClassicChallenge({ view_user }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [bonus, setBonus] = useState(25);
  const [gameData, setGameData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [paneltyId, setPaneltyId] = useState();
  const [form] = Form.useForm();
  const checkRoomCode = async (id) => {
    try {
      const response = await axios.get(
        `https://star-ludo-fetch-be.starludo.club/api/v1/ludo/board/game/${id}`,
        {
          headers: {
            "api-key": "7imfnc8mVLWwsPcgjYr4Gd",
          },
        }
      );

      setGameData(response?.data?.data);
    } catch (error) {}
  };
  const checkRoomCodeStuck = async (id) => {
    try {
      const response = await axios.get(
        `https://star-ludo-fetch-be.starludo.club/api/v1/ludo/board/stuck-game/${id}`,
        {
          headers: {
            "api-key": "7imfnc8mVLWwsPcgjYr4Gd",
          },
        }
      );
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const response = await API_MANAGER.getChallengeById(id);
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      setData(decryptedData);
      if (decryptedData?._id) {
        await checkRoomCode(decryptedData?._id);
        await checkRoomCodeStuck(decryptedData?._id);
      }
    } catch (error) {
      // message.error("Something went wrong!");
    }
  };
  const cancelChallenge = async () => {
    const confirm = window.confirm("are you sure to cancel");

    if (confirm) {
      try {
        const response = await API_MANAGER.updateStarLudoClassicGame({
          game_id: id,
          status: "cancelled",
        });
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updateByAdmin = async (userId) => {
    const confirm = window.confirm("are you sure to update");
    if (confirm) {
      try {
        const response = await API_MANAGER.updateStarLudoClassicGame({
          winner: userId,
          game_id: id,
        });
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const updatePenalty = async (data) => {
    const confirm = window.confirm(
      "Are you sure, you want to add penalty to this user?"
    );
    if (confirm) {
      try {
        const response = await API_MANAGER.addPanelty(
          { bonus: JSON.parse(data?.number), txn_msg: data?.reason },
          paneltyId
        );
        if (response?.data?.data?.status === 0) {
          alert("User wallet balance already low.");
        } else {
          alert("Penalty successfully added.");
        }
        getData();
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);
  return (
    <div className="viewGameContainer">
      <Modal
        title="Penalty"
        footer={false}
        centered
        open={isVisible}
        onCancel={() => {
          form.resetFields();
          setIsVisible(false);
          setPaneltyId();
        }}
      >
        <Form form={form} onFinish={updatePenalty}>
          <Form.Item
            name="number"
            rules={[
              {
                required: true,
                message: "Please enter amount.",
              },
            ]}
          >
            <Input type="number" max={250000} placeholder="Enter Amount" />
          </Form.Item>{" "}
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "Please enter reason.",
              },
            ]}
          >
            <Input placeholder="Enter reason" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className="boxContainer">
        <Row>Match Details</Row>
        {data?.Status != "waiting" &&
          data?.Status != "cancelled" &&
          data?.Status != "drop" &&
          data?.Status != "completed" && (
            <Row justify={"end"}>
              <Col>
                <Button onClick={() => cancelChallenge()}>Cancel</Button>
              </Col>
            </Row>
          )}

        <Row>
          <p>Check participants data, and announced result.</p>
        </Row>
        <Row align={"middle"} gutter={20}>
          <Col xs={24} md={8} lg={6}>
            <p>Match Fee: {data?.Game_Ammount}</p>
            {/* <p>{</p> */}
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>Prize: {data?.prize}</p>
          </Col>

          <Col xs={24} md={8} lg={6}>
            <p>Status: {gameData?.gameState || data?.Status}</p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>Room Code: {data?.Room_code}</p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>
              Game Updated By:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {data?.action_by && data?.action_by?.Name} -{" "}
                {data?.action_by && data?.action_by?.Phone}
              </span>
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {data?.actionby_Date &&
                  `(${moment(data?.actionby_Date).format("LLL")})`}
              </span>
            </p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>
              Exit:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {gameData?.isExit ? "Yes" : "No"}
              </span>
            </p>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <p>
              Valid Leave:{" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                {gameData?.isValidLeave ? "Yes" : "No"}
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Row gutter={[20, 20]}>
          <Col xs={24} lg={12}>
            <div className="boxContainer">
              <h3>Creator</h3>
              <div>
                <p>
                  User Name:{" "}
                  <span
                    className="tableLink"
                    onClick={() => {
                      if (data?.Created_by?._id && view_user) {
                        navigate(`/user/view/${data?.Created_by?._id}`);
                      }
                    }}
                  >
                    {data?.Created_by && data?.Created_by?.Name}
                  </span>
                </p>
              </div>

              <div>
                <p>
                  Star Classic Name:{" "}
                  <span>{gameData?.aPlayer?.[0]?.sUserName}</span>
                </p>
              </div>
              <div>
                <p>
                  Auto Exit:{" "}
                  <span>{gameData?.aPlayer?.[0]?.autoExit ? "Yes" : "No"}</span>
                </p>
              </div>

              <div>
                <p>
                  Created Time:{" "}
                  <span>{moment(data?.createdAt).format("LLL")}</span>
                </p>
              </div>
              <div>
                <p className="participation_status">
                  Participant Status: <span>{data?.Creator_Status}</span>
                </p>
              </div>

              {data?.Creator_Status_Updated_at && (
                <div>
                  <p>
                    Status Updated At:{" "}
                    <span>
                      {moment(data?.Creator_Status_Updated_at).format("LLL")}
                    </span>
                  </p>
                </div>
              )}
              {data?.Creator_Status_Reason && (
                <div>
                  <p>
                    Cancel Reason: <span>{data?.Creator_Status_Reason}</span>
                  </p>
                </div>
              )}

              {data?.Status !== "waiting" &&
                data?.Status != "cancelled" &&
                data?.Status != "drop" &&
                data?.Status != "completed" && (
                  <div className="form-group">
                    <Row align={"middle"} gutter={20}>
                      <Col xs={12} lg={6}>
                        <Button
                          className="w-100"
                          onClick={() => {
                            updateByAdmin(data?.Created_by?._id);
                          }}
                        >
                          Win
                        </Button>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Button
                          className="w-100"
                          onClick={() => {
                            updateByAdmin(data?.Accepetd_By?._id);
                          }}
                        >
                          Lose
                        </Button>
                      </Col>
                    </Row>
                    <div>
                      <p>Add Penalty</p>
                      <Row gutter={16}>
                        <Col>
                          <Button
                            onClick={() => {
                              setPaneltyId(data?.Created_by?._id);
                              setIsVisible(true);
                            }}
                          >
                            Add Penalty
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="boxContainer">
              <h3>Acceptor</h3>
              <div>
                <p>
                  User Name:{" "}
                  <span
                    className="tableLink"
                    onClick={() => {
                      if (data?.Accepetd_By?._id && view_user) {
                        navigate(`/user/view/${data?.Accepetd_By?._id}`);
                      }
                    }}
                  >
                    {data?.Accepetd_By && data?.Accepetd_By?.Name}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  Star Classic Name:{" "}
                  <span>{gameData?.aPlayer?.[0]?.sUserName}</span>
                </p>
              </div>
              <div>
                <p>
                  Auto Exit:{" "}
                  <span>{gameData?.aPlayer?.[0]?.autoExit ? "Yes" : "No"}</span>
                </p>
              </div>
              <div>
                <p>
                  Join Time:{" "}
                  <span>
                    {moment(data?.Acceptor_by_Creator_at).format("LLL")}
                  </span>
                </p>
              </div>
              <div>
                <p className="participation_status">
                  Participant Status:{" "}
                  <span className="">
                    {data?.Acceptor_status ? data?.Acceptor_status : "----"}
                  </span>
                </p>
              </div>{" "}
              {data?.Acceptor_status_Updated_at && (
                <div>
                  <p>
                    Status Updated At:{" "}
                    <span>
                      {moment(data?.Acceptor_status_Updated_at).format("LLL")}
                    </span>
                  </p>
                </div>
              )}
              {data?.Acceptor_status_reason && (
                <div>
                  <p>
                    Cancel Reason: <span>{data?.Acceptor_status_reason}</span>
                  </p>
                </div>
              )}
              <Row gutter={[20, 20]} className="mb-20"></Row>
              {data?.Status !== "waiting" &&
                data?.Status != "cancelled" &&
                data?.Status != "drop" &&
                data?.Status != "completed" && (
                  <div className="form-group">
                    <Row align={"middle"} gutter={20}>
                      <Col xs={12} lg={6}>
                        <Button
                          onClick={() => {
                            updateByAdmin(data?.Accepetd_By?._id);
                          }}
                          className="w-100"
                        >
                          Win
                        </Button>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Button
                          onClick={() => {
                            updateByAdmin(data?.Created_by?._id);
                          }}
                          className="w-100"
                        >
                          Lose
                        </Button>
                      </Col>
                    </Row>
                    <div>
                      <p>Add Penalty</p>
                      <Row gutter={16}>
                        <Col>
                          <Button
                            onClick={() => {
                              setPaneltyId(data?.Accepetd_By?._id);
                              setIsVisible(true);
                            }}
                          >
                            Add Penalty
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
            </div>
          </Col>
          <Row gutter={[20, 20]}>
            {data?.Creator_Screenshot && (
              <Col>
                <div className="boxContainer" style={{ margin: "12px" }}>
                  <p>Screenshot: </p>
                  <img
                    src={data?.Creator_Screenshot}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    alt="not loaded"
                  />
                </div>
              </Col>
            )}
          </Row>
        </Row>
      </div>
    </div>
  );
}

export default React.memo(ViewStarClassicChallenge);

import React, { useEffect, useState } from "react";
import { Row, Col, message, Switch, DatePicker, Spin } from "antd";
import API_MANAGER from "../API";
import Card from "../components/layout/dashboard/Card";
import HELPERS from "../utils/helper";
function Dashboard({ today_filtrers }) {
  const [totalUser, setTotalUser] = useState(0);
  const [allChallanges, setAllChallanges] = useState(0);
  const [totalDepositCounts, setTotalDepositCounts] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [todayData, setTodayData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [today, setToday] = useState(false);
  const [totalCommission, setTotalCommission] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getAllUSer = async () => {
    try {
      const response = await API_MANAGER.getAllUser();
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setTotalUser(decryptedData || {});
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const getAllChallenge = async () => {
    try {
      const response = await API_MANAGER.getAllChallengeCount();
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setAllChallanges(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const getDepositCounts = async () => {
    try {
      const response = await API_MANAGER.getTotalDeposit();
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      setTotalDepositCounts(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const getTotalWithdrawal = async () => {
    try {
      const response = await API_MANAGER.getWithdrawCounts();
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setTotalWithdrawal(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const getTodayData = async () => {
    try {
      let params = {};
      if (startDate && endDate) {
        params["FROM_DATE"] = startDate;
        params["TO_DATE"] = endDate;
      }
      const response = await API_MANAGER.getTodayData(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setTodayData(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const getTotalEarning = async () => {
    try {
      setLoading(true);
      const response = await API_MANAGER.getTotalAdminEarning({});
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      setTotalCommission(decryptedData);
      getAllUSer();
      getAllChallenge();
      getDepositCounts();
      getTotalWithdrawal();
      getTodayData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error?.response?.data);
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getTotalEarning();
  }, []);
  useEffect(() => {
    if (startDate && endDate) getTodayData();
  }, [startDate, endDate]);

  return (
    <div>
      {/* <SubLayout page={"ss"}> */}
      <Spin spinning={loading}>
        {error ? (
          <div className="dashboardContainer h-100">
            <Row
              align={"middle"}
              justify={"center"}
              style={{ height: "500px" }}
            >
              <Col>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  You have reached the daily limit to view the dashboard, please
                  visit again tomorrow, Thank You!
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Maximum Limit: {error?.max_limit}
                </p>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="dashboardContainer">
            <Row justify={"space-between"}>
              <Col>
                {today && (
                  <Row gutter={16}>
                    <Col>
                      <DatePicker
                        value={startDate}
                        onChange={(e) => setStartDate(e)}
                        placeholder="Start Date"
                      />
                    </Col>
                    <Col>
                      <DatePicker
                        value={endDate}
                        onChange={(e) => setEndDate(e)}
                        placeholder="End Date"
                      />
                    </Col>
                  </Row>
                )}
              </Col>
              {today_filtrers && (
                <Col style={{ marginTop: "20px" }}>
                  <Row align={"middle"} gutter={8}>
                    <Col>
                      <Switch onChange={(e) => setToday(e)} />
                    </Col>
                    <Col className="mainHeading">Apply Filter</Col>
                  </Row>
                </Col>
              )}
            </Row>
            {!today ? (
              <div>
                <p className="mainHeading">All User</p>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL ADMIN</p>
                      <p className="amount">{totalUser?.totalAdmin}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL USER</p>
                      <p className="amount">{totalUser?.totalUser}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">ACTIVE USER</p>
                      <p className="amount">{totalUser?.activeUser}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">BLOCKED USER</p>
                      <p className="amount">{totalUser?.blockedUser}</p>
                    </div>
                  </Col>
                </Row>
                <p className="mainHeading">Challenge</p>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">ALL CHALLENGE</p>
                      <p className="amount">{allChallanges?.totalChallanges}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">COMPLETED CHALLENGE</p>
                      <p className="amount">
                        {allChallanges?.completedChallanges}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">CONFLICT CHALLENGE</p>
                      <p className="amount">
                        {allChallanges?.conflictChallanges}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">CANCELLED CHALLENGE</p>
                      <p className="amount">
                        {allChallanges?.cancelledChallanges}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">RUNNING CHALLENGE</p>
                      <p className="amount">
                        {allChallanges?.runningChallanges}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">NEW CHALLENGE</p>
                      <p className="amount">{allChallanges?.newChallanges}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">DROP CHALLENGE</p>
                      <p className="amount">{allChallanges?.dropChallanges}</p>
                    </div>
                  </Col>
                </Row>
                <p className="mainHeading">Deposit</p>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL COMMISSION</p>
                      <p className="amount">
                        {totalCommission?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL DEPOSIT</p>
                      <p className="amount">
                        {totalDepositCounts?.totalDeposit?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL REDEEM</p>
                      <p className="amount">
                        {totalDepositCounts?.totalRedeem?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL BONUS</p>
                      <p className="amount">
                        {todayData?.totalBonus?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL Penalty</p>
                      <p className="amount">
                        {todayData?.totalPanelty?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">USER WALLET</p>
                      <p className="amount">
                        {todayData?.walletBalance?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">REFERRAL WALLET</p>
                      <p className="amount">
                        {todayData?.refferalWallet?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">HOLD BALANCE</p>
                      <p className="amount">
                        {todayData?.holdBalance?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">TOTAL REQUEST</p>
                      <p className="amount">
                        {totalDepositCounts?.totalRequest}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">PENDING REQUEST</p>
                      <p className="amount">
                        {totalDepositCounts?.pendingRequest}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">REJECTED REQUEST</p>
                      <p className="amount">
                        {totalDepositCounts?.rejectedRequest}
                      </p>
                    </div>
                  </Col>
                </Row>
                <p className="mainHeading">Withdrawal Request</p>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Total Request</p>
                      <p className="amount">{totalWithdrawal?.totalRequest}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Total Manual Request</p>
                      <p className="amount">
                        {totalWithdrawal?.totalManualRequest}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Total Withdrwal</p>
                      <p className="amount">
                        {totalWithdrawal?.totalWithdraw?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Total Auto Withdrwal</p>
                      <p className="amount">
                        {totalWithdrawal?.totalAutoWithdraw?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Total Manual Withdrwal</p>
                      <p className="amount">
                        {totalWithdrawal?.totalManualWithdraw?.[0]?.totalAmount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Pending Request</p>
                      <p className="amount">
                        {totalWithdrawal?.pendingRequest}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Manual Pending Request</p>
                      <p className="amount">
                        {totalWithdrawal?.pendingManualRequest}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Pending Request Amount</p>
                      <p className="amount">
                        {totalWithdrawal?.pendingRequestAmount?.[0]
                          ?.totalAmount || 0}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Manual Pending Request Amount</p>
                      <p className="amount">
                        {totalWithdrawal?.pendingManualRequestAmount?.[0]
                          ?.totalAmount || 0}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Rejected Request</p>
                      <p className="amount">
                        {totalWithdrawal?.rejectedRequest}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <div className="dashboardCard">
                      <p className="heading">Manual Rejected Request</p>
                      <p className="amount">
                        {totalWithdrawal?.rejectedManualRequest}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <p className="mainHeading">Filtered Data</p>
                <Row gutter={[24, 24]}>
                  <Card
                    name={"Commission"}
                    amount={todayData?.commissionEntry?.[0]?.totalAmount?.toFixed(
                      2
                    )}
                  />
                  <Card
                    name={"Star Classic Commission"}
                    amount={todayData?.starclassiccommissionEntry?.[0]?.totalAmount?.toFixed(
                      2
                    )}
                  />
                  <Card
                    name={"Total Deposit"}
                    amount={todayData?.depositEntry?.[0]?.totalAmount}
                  />
                  <Card
                    name={"UPI Deposit"}
                    amount={todayData?.upiDepositEntry?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Phonepe Deposit"}
                    amount={todayData?.phonepeDepositEntry?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Redeem"}
                    amount={todayData?.redeemEntry?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Bonus"}
                    amount={todayData?.bonusEntry?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Penalty"}
                    amount={todayData?.paneltyEntry?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Total Withdrawal"}
                    amount={todayData?.withdrawlDone?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Auto Withdrawal"}
                    amount={todayData?.autoWithdrawlDone?.[0]?.totalAmount}
                  />
                  <Card
                    name={"Manual Withdrawal"}
                    amount={todayData?.manualWithdrawlDone?.[0]?.totalAmount}
                  />
                  {/* <Card name={"Won amount"} amount={todayData?.totolWonAmount} />
              <Card name={"Lose amount"} amount={todayData?.totalLoseAmount} />
              <Card
                name={"Hold balance"}
                amount={todayData?.totalHoldBalance}
              /> */}
                  {/* <Card
                name={"Withdrawal hold balance"}
                amount={todayData?.totalWithdrawHold}
              /> */}
                  {/* <Card
                name={"Referral earning"}
                amount={todayData?.totalReferralEarning}
              />
              <Card
                name={"Referral wallet"}
                amount={todayData?.totalReferralWallet}
              /> */}
                  <Card name={"All Game"} amount={todayData?.allGames} />
                  <Card
                    name={"Success game"}
                    amount={todayData?.successGames}
                  />
                  <Card name={"Cancel game"} amount={todayData?.cancelgames} />
                  <Card name={"User"} amount={todayData?.userEntry} />
                  {/* <Card
                name={"User wallet"}
                amount={todayData?.totalWalletbalance}
              /> */}
                </Row>
              </div>
            )}
          </div>
        )}
      </Spin>
      {/* </SubLayout> */}
    </div>
  );
}

export default Dashboard;

import {
  Row,
  Col,
  message,
  Button,
  Radio,
  Form,
  Modal,
  Input,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import UserGameHistory from "../../components/common/user/UserGameHistory";
import UserDepositHistory from "../../components/common/user/UserDepositHistory";
import UserReferHistory from "../../components/common/user/UserReferHistory";
import UserWithDrawHistory from "../../components/common/user/UserWithDrawHistory";
import UserKYC from "../../components/common/user/UserKYC";
import HELPERS from "../../utils/helper";
function ViewUser({ permissions }) {
  const [user, setUser] = useState({});
  let [mismatchValue, setMisMatchValue] = useState(0);
  const [referCount, setReferralcount] = useState([]);
  const [reportType, setReportType] = useState(null);
  const { id } = useParams();
  const [updateUser, setUpdateUser] = useState(false);
  const [blockUser, setBlockUser] = useState(false);
  const [redAlertUser, setRedAlertUser] = useState(false);
  const [alertUserAmount, setAlertUserAmount] = useState(false);

  const referralCount = async (referral_code) => {
    try {
      const response = await API_MANAGER.referralCount(referral_code);
      // setUser(response?.data?.data);
      setReferralcount(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  const getData = async () => {
    try {
      const response = await API_MANAGER.getUserById(id);
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      setUser(decryptedData);
      setMisMatchValue(
        decryptedData?.Wallet_balance -
          (decryptedData?.wonAmount -
            decryptedData?.loseAmount +
            decryptedData?.totalDeposit +
            decryptedData?.referral_earning +
            decryptedData?.totalBonus -
            (decryptedData?.totalWithdrawl +
              decryptedData?.referral_wallet +
              decryptedData?.withdraw_holdbalance +
              decryptedData?.hold_balance +
              decryptedData?.totalPenalty))
      );
      referralCount(decryptedData?.referral_code);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const blockUnblockPlayer = async (data) => {
    const confirmBox = window.confirm(
      `are you sure you want to unblock ${user?.Name}`
    );
    if (confirmBox === true) {
      const userType = user?.user_type === "Block" ? "User" : "Block";

      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: user?._id,
          user_type: userType,
          remark: data?.remark,
        });
        message.success("Updated successfully!");
        setBlockUser(false);

        getData();
      } catch (error) {
        setBlockUser(false);

        message.error("Something went wrong!");
      }
    }
  };
  const handleRedHighlightUser = async (data) => {
    const confirmBox = window.confirm(
      `are you sure that you want to ${
        user?.isRedHighlight ? "remove" : "set"
      } red alert ${user?.Name}`
    );
    if (confirmBox === true) {
      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: user?._id,
          isRedHighlight: user?.isRedHighlight ? false : true,
          remark: data?.remark,
          isUpdateRedAlert: true,
        });
        setRedAlertUser(false);
        message.success("Updated successfully!");
        getData();
      } catch (error) {
        setRedAlertUser(false);

        message.error("Something went wrong!");
      }
    }
  };
  const updateUserRedAlertAmount = async (data) => {
    const confirmBox = window.confirm(
      `are you sure that you want to red alert amount of ${user?.Name}`
    );
    if (confirmBox === true) {
      try {
        await API_MANAGER.updateUserRedAlertAmount({
          _id: user?._id,
          red_alert_amount: data?.amount,
          remark: data?.remark,
        });
        setAlertUserAmount(false);
        message.success("Updated successfully!");
        getData();
      } catch (error) {
        setAlertUserAmount(false);

        message.error("Something went wrong!");
      }
    }
  };
  const updateMismatch = async (id) => {
    try {
      await API_MANAGER.updateMisMatch(id);
      message.success("Updated Successfully!");
      getData();
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const updateHold = async (id) => {
    try {
      await API_MANAGER.updateHold(id);
      message.success("Updated Successfully!");
      getData();
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
    // axios.patch(baseUrl + `user/Hold/clear/${Id}`, { headers }).then((res) => {
  };
  const handleUserUpdateSubmit = async (data) => {
    try {
      if (data?.type && data?.amount) {
        await API_MANAGER.updateUserInfo(user?._id, {
          [data?.type]: data?.amount,
        });
        setUpdateUser(false);
        message.success("Updated Successfully!");
        getData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
    // axios.patch(baseUrl + `user/Hold/clear/${Id}`, { headers }).then((res) => {
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <div>
      <div className="deposit_history">
        <Modal
          centered
          open={blockUser || redAlertUser}
          closable={true}
          footer={false}
          onCancel={() => {
            setRedAlertUser(false);
            setBlockUser(false);
          }}
          // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
        >
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={(data) => {
              redAlertUser
                ? handleRedHighlightUser(data)
                : blockUnblockPlayer(data);
            }}
          >
            <Form.Item
              name={"remark"}
              label="Remark*"
              rules={[{ required: true, message: "Please enter remark." }]}
            >
              <Input placeholder="Enter remark" />
            </Form.Item>
            <Row justify={"end"}>
              <Col>
                <Button htmlType="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Enter Details"
          footer={false}
          centered
          open={alertUserAmount}
          onCancel={() => {
            setAlertUserAmount(false);
          }}
        >
          <Form
            onFinish={(values) => {
              updateUserRedAlertAmount(values);
            }}
          >
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Please enter amount.",
                },
              ]}
            >
              <Input type="number" placeholder="eg:- 500" />
            </Form.Item>{" "}
            <Form.Item
              name="remark"
              rules={[
                {
                  required: true,
                  message: "Please enter reason.",
                },
              ]}
            >
              <Input placeholder="please enter reason" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div className="user_management">
        <p className="heading">User Details</p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Name</p>
              <p className="value">{user?.Name}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Verified</p>
              <p className="value">{user?.verified ? "Yes" : "No"}</p>
            </div>
          </Col>
          {permissions?.view_phone && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="userCard">
                <p className="item">Phone</p>
                <p className="value">{user?.Phone}</p>
              </div>
            </Col>
          )}

          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Wallet Balance</p>
              <p className="value">{user?.Wallet_balance?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Withdrawal Amount balance</p>
              <p className="value">{user?.withdrawAmount?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Withdrawal Hold balance</p>
              <p className="value">{user?.withdraw_holdbalance?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Referral balance</p>
              <p className="value">{user?.referral_wallet?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Referral count</p>
              <p className="value">{referCount ? referCount : 0}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Referral earning</p>
              <p className="value">{user?.referral_earning?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Total Deposit</p>
              <p className="value">{user?.totalDeposit?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Total Withdrawal</p>
              <p className="value">{user?.totalWithdrawl?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Total Bonus</p>
              <p className="value">{user?.totalBonus?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Total Penalty</p>
              <p className="value">{user?.totalPenalty?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Hold balance</p>
              <p className="value">{user?.hold_balance?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Won amount</p>
              <p className="value">{user?.wonAmount?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Lose amount</p>
              <p className="value">{user?.loseAmount?.toFixed(2)}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Calculated wallet balance</p>
              <p className="value">
                {(
                  user?.wonAmount -
                  user?.loseAmount +
                  user?.totalDeposit +
                  user?.referral_earning +
                  user?.totalBonus -
                  (user?.totalWithdrawl +
                    user?.referral_wallet +
                    user?.withdraw_holdbalance +
                    user?.hold_balance +
                    user?.totalPenalty)
                )?.toFixed(2)}
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Mismatch wallet balance</p>
              <p className="value">{mismatchValue}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Referral code </p>
              <p className="value">{user?.referral_code}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Referral by </p>
              <p className="value">{user?.referral}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Account created at</p>
              <p className="value">{moment(user?.createdAt).format("LLL")}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Account updated at</p>
              <p className="value">{moment(user?.updatedAt).format("LLL")}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Aadhar Name</p>
              <p className="value">{user?.aadhar_name}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Aadhar Number</p>
              <p className="value">{user?.aadhar}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Gender</p>
              <p className="value">
                {user?.gender === "M"
                  ? "Male"
                  : user?.gender === "F"
                  ? "Female"
                  : ""}
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> Address</p>
              <p className="value">{user?.address}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item"> DOB</p>
              <p className="value">{user?.dob}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Parent's Name</p>
              <p className="value">{user?.parent}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Remark</p>
              <p className="value">{user?.remark}</p>
            </div>
          </Col>
          {user?.user_profile_image && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="userCard">
                <p className="item">User Profile</p>
                <img
                  src={`data:image/png;base64,${user?.user_profile_image}`}
                  alt="Base64 representation"
                />
              </div>
            </Col>
          )}
        </Row>
        <p className="heading">Bank Details</p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Account holder name </p>
              <p className="value">{user?.holder_name}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">IFSC code</p>
              <p className="value">{user?.ifsc_code}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Account number </p>
              <p className="value">{user?.account_number}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">UPI ID</p>
              <p className="value">{user?.upi_id}</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Mismatch</p>
              <p className="value">
                <Button onClick={() => updateMismatch(user?._id)}>
                  CLEAR ({mismatchValue?.toFixed(2)})
                </Button>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Hold</p>
              <p className="value">
                <Button onClick={() => updateHold(user?._id)}>
                  CLEAR ({user?.hold_balance?.toFixed(2)})
                </Button>
              </p>
            </div>
          </Col>
          {permissions?.update_user && (
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="userCard">
                <p className="item">Update User</p>
                <p className="value">
                  <Button onClick={() => setUpdateUser(true)}>
                    Update User
                  </Button>
                </p>
              </div>
            </Col>
          )}

          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Update User</p>
              <p className="value">
                <Button onClick={() => setBlockUser(true)}>
                  {user?.user_type === "Block" ? "Unblock" : "Block"}
                </Button>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Red Alert</p>
              <p className="value">
                <Button onClick={() => setRedAlertUser(true)}>
                  {user?.isRedHighlight ? "Remove Red Alert" : "Set Red Alert"}
                </Button>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="userCard">
              <p className="item">Red Alert Amount</p>
              <p className="value">
                <Button onClick={() => setAlertUserAmount(true)}>
                  Set Red Alert Amount{" "}
                  {`(${user?.red_alert_amount ? user?.red_alert_amount : 0})`}
                </Button>
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={20} align={"middle"}>
          <Col>
            <Switch
              onChange={(e) => {
                if (e) {
                  setReportType("Game");
                } else {
                  setReportType(null);
                }
              }}
            />
          </Col>
          <Col>
            <p className="heading">View History</p>
          </Col>
        </Row>
        {reportType && (
          <div className="radioButtons">
            <Radio.Group
              defaultValue={reportType}
              onChange={(e) => setReportType(e?.target?.value)}
            >
              <Radio.Button value="Game">Game</Radio.Button>
              <Radio.Button value="Deposit">Deposit</Radio.Button>
              <Radio.Button value="Bonus">Bonus</Radio.Button>
              <Radio.Button value="Panelty">Panelty</Radio.Button>
              <Radio.Button value="Redeem">Redeem</Radio.Button>
              <Radio.Button value="Withdraw">Withdraw</Radio.Button>
              <Radio.Button value="Referral">Referral</Radio.Button>
              <Radio.Button value="KYC">KYC</Radio.Button>
            </Radio.Group>

            <div
              style={{
                marginTop: "30px",
              }}
            >
              {reportType === "Game" && <UserGameHistory id={user?._id} />}
              {reportType === "Deposit" && (
                <UserDepositHistory
                  id={user?._id}
                  type="DEPOSIT"
                  view_phone={permissions?.view_phone}
                />
              )}
              {reportType === "Bonus" && (
                <UserDepositHistory
                  id={user?._id}
                  type="BONUS"
                  view_phone={permissions?.view_phone}
                />
              )}
              {reportType === "Panelty" && (
                <UserDepositHistory
                  id={user?._id}
                  type="PENALTY"
                  view_phone={permissions?.view_phone}
                />
              )}
              {reportType === "Redeem" && (
                <UserDepositHistory
                  id={user?._id}
                  type="REDEEM"
                  view_phone={permissions?.view_phone}
                />
              )}
              {reportType === "Withdraw" && (
                <UserWithDrawHistory id={user?._id} />
              )}
              {reportType === "Referral" && (
                <UserReferHistory id={user?.referral_code} />
              )}
              {reportType === "KYC" && (
                <UserKYC id={user?._id} view_phone={permissions?.view_phone} />
              )}
            </div>
          </div>
        )}
        <Modal
          onCancel={() => setUpdateUser(false)}
          centered
          open={updateUser}
          footer={false}
          title="Update User"
        >
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={handleUserUpdateSubmit}
          >
            <Form.Item
              name={"type"}
              label="Select type"
              rules={[{ required: "true", message: "Please select type" }]}
            >
              <Select>
                {/* <Select.Option value="Name"> Name</Select.Option> */}
                <Select.Option value="Wallet_balance">
                  Wallet Balance
                </Select.Option>
                <Select.Option value="hold_balance">Hold Balance</Select.Option>
                <Select.Option value="wonAmount">Won Amount</Select.Option>
                <Select.Option value="loseAmount">Lose Amount</Select.Option>
                <Select.Option value="withdrawAmount">
                  Withdraw Amount
                </Select.Option>
                <Select.Option value="totalWithdrawl">
                  Total Withdraw
                </Select.Option>
                <Select.Option value="referral">Refer By</Select.Option>
                <Select.Option value="referral_code">
                  Referral Code
                </Select.Option>
                <Select.Option value="referral_wallet">
                  Referral Wallet
                </Select.Option>
                <Select.Option value="max_limit">Max Limit</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name={"amount"}
              label="Enter"
              rules={[{ required: "true", message: "Please enter value" }]}
            >
              <Input placeholder="Enter value." />
            </Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ViewUser;
